import { getMetaContent } from "./get-meta-content"

const RAILS_ENV = getMetaContent("RAILS_ENV")

const logger = {
  info: (...args: unknown[]): void => {
    if (RAILS_ENV !== "production") {
      console.info(...args)
    }
  },
  log: (...args: unknown[]): void => {
    if (RAILS_ENV !== "production") {
      console.log(...args)
    }
  },
  warn: (...args: unknown[]): void => {
    console.warn(...args)
  },
  error: (...args: unknown[]): void => {
    console.error(...args)
  },
  debug: (...args: unknown[]): void => {
    if (RAILS_ENV !== "production") {
      console.debug(...args)
    }
  },
}

export default logger
